import Vue from "vue";
import Router from "vue-router";
Vue.use(Router)


let router = new Router({
	// mode: 'history',
	routes: [{
			path: '/',
			name: 'index',
			component: () => import('views/index.vue')
		},
		{
			path: '/04zhihuiwenlv',
			name: '智慧文旅',
			component: () => import('views/04zhihuiwenlv.vue')
		},
		{
			path: '/03zhihuisuyuan',
			name: '智慧溯源',
			component: () => import('views/03zhihuisuyuan.vue')
		},
		{
			path: '/01zhihuichengshi',
			name: '智慧城市',
			component: () => import('views/01zhihuichengshi.vue')
		},
		{
			path: '/02shuzixiangcun',
			name: '数字乡村',
			component: () => import('views/02shuzixiangcun.vue')
		},
		{
			path: '/05xinhengdianshang',
			name: '新亨电商',
			component: () => import('views/05xinhengdianshang.vue')
		},
		
		
		
		
		{
			path: '/11zhihuijiaotong',
			name: '智慧交通',
			component: () => import('views/11zhihuijiaotong.vue')
		},
		{
			path: '/12xinshidaiwenming',
			name: '智慧医疗教育',
			component: () => import('views/12xinshidaiwenming.vue')
		},
		{
			path: '/15zhihuichengguan',
			name: '智慧城管',
			component: () => import('views/15zhihuichengguan.vue')
		},
		{
			path: '/text',
			name: 'text',
			component: () => import('views/text.vue')
		},
		{
			path: '/text1',
			name: 'text1',
			component: () => import('views/text1.vue')
		},
		



	]
})
export default router;
